<template>
    <div class="create-task">
        <div v-if="!isLoading">
            <div class="row mb-4 justify-content-center">
                <div class="col-lg-10 pt-3 text-center">
                    <h3 class="fw-normal">What do you need?</h3>
                    <p>Select your service and fill in your project details in just a few steps</p>
                    <form class="task-search-form position-relative">
                        <input v-model="searchQuery" class="task-search-input disable-x pe-5 form-control form-control-lg" type="search" placeholder="LogoType, business cards, social media graphics..." aria-label="LogoType, business cards, social media graphics...">
                        <svg class="svgIcon-search position-absolute" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style="top: 18px; right: 20px;"><g data-v-333ad59a="" fill="none" fill-rule="evenodd" stroke="#A1ABB3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(2 2)"><path data-v-333ad59a="" d="M20 20l-6-6"></path> <circle data-v-333ad59a="" cx="8" cy="8" r="8"></circle></g></svg>
                    </form>
                </div>
            </div>
            <div class="row g-3 mb-5">
                <div v-for="(task, tIndex) in searchedTasks" :key="tIndex" class="col-lg-6">
                    <router-link :to="`/tasks/launch?task_id=${task.id}`">
                        <div class="rounded bg-white d-flex align-items-center cursor-pointer shadow border border-1">
                            <div class="p-3">
                                <img style="height:50px;" class="" :alt="task.name" :src="absoluteUrl(task.image)">
                            </div>
                            <div class="flex-grow-1 text-truncate pe-4">
                                <h6 class="mb-0">{{task.name}}</h6>
                                <span class="text-dark">{{task.description}}</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-if="!searchedTasks.length" class="text-center">
                    <p><em>No task to show</em></p>
                    <a v-if="!activeUser.has_subscribed" :href="absoluteUrl(`/subscription`)" target="_blank" class="btn btn-primary">Subscribe Now</a>
                </div>
            </div>
        </div>
        <is-loading v-if="isLoading" />
    </div>
</template>

<script>
import ProgressStep from '@/components/ProgressStep.vue'
import IsLoading from '@/components/IsLoading.vue'

export default {
    name: "create-task",
    components:{
        ProgressStep,
        IsLoading
    },
    data(){
        return{
            isLoading:true,
            searchQuery: ""
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        taskList(){
            return this.$store.state.catalog.tasks
        },
        searchedTasks(){
            return this.taskList.filter(task => task.name ? task.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : false)
        },
    },
    created(){
        this.$store.dispatch("fetchTasks")
        .then(response => { this.isLoading = false })
    },
}
</script>
