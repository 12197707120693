<template>
  <div class="position-relative m-4">
    <div class="progress px-1" style="height:2px;background-color:#fff;">
        <div class="progress-bar" role="progressbar" 
        :style="`width:${step == 1?'0':step == 2 ? '33':step == 3 ? '67':'100'}%; background-color:#0f2d25;`"
         aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div style="transform: translate(0%, -50%)"
    class="dots position-absolute top-0 start-0 end-0 d-flex justify-content-between">
        <span style="width:20px; height:20px" class="bg-primary shadow-sm rounded-pill border border-2 border-white"></span>
        <span style="width:20px; height:20px" :class="step >= 2 ? 'bg-primary':'bg-light'" class="shadow-sm rounded-pill border border-2 border-white"></span>
        <span style="width:20px; height:20px" :class="step >= 3 ? 'bg-primary':'bg-light'" class="shadow-sm rounded-pill border border-2 border-white"></span>
        <span style="width:20px; height:20px" :class="step == 4 ? 'bg-primary':'bg-light'" class="shadow-sm rounded-pill border border-2 border-white"></span>
    </div>
    <div style="transform: translate(0%, -50%);left:-10px;right:-10px; top:-20px;"
    class="labels position-absolute d-flex justify-content-between">
        <span class="fs-12">Account </span>
        <span class="fs-12">Activation </span>
        <span class="fs-12">Plan </span>
        <span class="fs-12"> First Task </span>
    </div>
</div>
</template>

<script>
export default {
    props:{
        step:{
            default:1
        }
    }
}
</script>

<style>

</style>